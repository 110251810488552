import React, { useState, useEffect } from "react";
import Select from "react-select";

import useForm from "../../shared/useForm";
import { ApiUnit } from "../../unit/ApiUnit";
import {
  Button,
  Table,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  ButtonGroup,
} from "reactstrap";
import { PDFExport } from "@progress/kendo-react-pdf";

import {
  GetLanguageString,
  NoResult,
  SetReportTitle,
} from "../../helper/Components";
import { GetCustomStyles } from "../../helper/Method";
import { LoadingSpinner } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { addMember } from "../../member/ApiMember";
import { saveAs } from "file-saver";
import { FcPrint } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";

const UnitJaizaReport = () => {
  const [subUnitLoading, setSubUnitLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitType, setUnitType] = useState("");
  const [unitMembers, setUnitMembers] = useState([]);
  const [reportTitle, setReportTitle] = useState("");
  const [reportResponse, setReportResponse] = useState();
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [modal, setModal] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [reportSearchMemberModel, setReportSearchMemberModel] = useState({
    unitId: loggedInUnitId,
    unitName: "",
  });

  let className = "";
  const unitTypeName = "null";
  let totalCount = {
    totalMemberCount: 0,
    multazimCount: 0,
    mutazirCount: 0,
    mubtadiCount: 0,
    tawajahTalabCount: 0,
  };
  const customStyles = {
    border: "2px solid black",
    fontWeight: "bold",
  };

  const { values, setValues } = useForm(reportSearchMemberModel);

  const pdfExportComponent = React.useRef(null);
  const [unitList, setUnitList] = useState([]);
  useEffect(() => {
    ApiUnit()
      .getUnitList(
        loggedInUnitId,
        loggedInUnitId,
        false,
        unitTypeName,
        excludeExUnit
      )
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [loggedInUnitId, excludeExUnit]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(
          record.value,
          loggedInUnitId,
          false,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: record.value,
        unitName: record.label,
        unitTypeName: record.unitName,
      });
    } else {
      ApiUnit()
        .getUnitList(
          loggedInUnitId,
          loggedInUnitId,
          false,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: 0,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchButton(true);
    if (values.unitName !== "") setReportSearchMemberModel(values);
    else {
      setReportSearchMemberModel({
        ...values,
        unitName: unitList[0]?.unitList[0]?.name,
      });
    }
  };

  useEffect(() => {
    if (reportSearchMemberModel.unitId > 0 && searchButton) {
      setSubUnitLoading(true);
      addMember()
        .GetUnitJaizaReport(
          reportSearchMemberModel.unitId
        )
        .then((res) => {
          setReportResponse(res.data);
          setUnitMembers(res.data.unitMembers);
          setUnitType(res.data.unitTypeName);
          setSubUnitLoading(false);
        })
        .catch((err) => {
          setSubUnitLoading(false);
          console.log(err);
        });
    }
  }, [reportSearchMemberModel, searchButton]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  const excelDownload = () => {
    if (reportResponse?.unitMembers.length !== 0 && searchButton) {
      reportResponse.unitName = reportSearchMemberModel.unitName;
      addMember()
        .GenerateUnitJaizaExcelReport(reportResponse)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "UnitJaizaReport.xlsx");
        })
        .catch((err) => console.log(err));
    }
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <div>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="common_generate_report" />}</h5>
          </div>
          <div className="ibox-content">
            <Form onSubmit={handleSubmit}>
              <Row>
                {unitList.map((record, index) => {
                  const unitOptions = [];
                  record.unitList.forEach((d) =>
                    d.unitTypeName !== "Munfarid" &&
                    d.unitTypeName !== "UsraMuavin"
                      ? unitOptions.push({
                          value: d.id,
                          label: d.name,
                          unitName: d.unitTypeName,
                        })
                      : null
                  );
                  let clearable = false;
                  if (index === 0) clearable = true;
                  let selected = {};
                  let unitName = "";
                  if (record.parentUnitName !== null)
                    unitName = record.parentUnitName;
                  if (record.selectedUnit !== null) {
                    selected = {
                      value: record.selectedUnit.id,
                      label: record.selectedUnit.name,
                    };
                  }

                  return (
                    <Col md="2" key={index}>
                      <FormGroup>
                        <Label className="col-form-label">
                          {unitName !== "" ? (
                            <GetLanguageString
                              props="member_select_unit_name"
                              value={unitName}
                            />
                          ) : (
                            <GetLanguageString props="member_select_unit" />
                          )}
                        </Label>
                        <Select
                          options={unitOptions}
                          value={selected}
                          className="basic-single ur"
                          classNamePrefix="select"
                          isClearable={clearable}
                          onChange={handleChangeUnits}
                        />
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup check>
                    <Label check for="excludeExUnit">
                      <Input
                        id="excludeExUnit"
                        type="checkbox"
                        name="excludeExUnit"
                        checked={!excludeExUnit}
                        value={!excludeExUnit}
                        onChange={handleExcludeExUnit}
                      />
                      {
                        <GetLanguageString props="member_search_member_ex_unit" />
                      }
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="m-t-md">
                <Col className="text-center">
                  <FormGroup>
                    <Button color="primary" className="m-l-sm" type="submit">
                      {<GetLanguageString props="common_view_report" />}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        {searchButton ? (
          <div className="ibox">
            <div className="ibox-title">
              {unitMembers.length !== 0 ? (
                <ButtonGroup className={GetCustomStyles().err_btn_group_style}>
                  <Button color="default" size="sm" onClick={excelDownload}>
                    <RiFileExcel2Line
                      size={15}
                      color="#009440"
                      style={{ marginRight: 3, marginBottom: 1 }}
                    />
                    {<GetLanguageString props="member_report_export_excel" />}
                  </Button>
                  <Button color="default" size="sm" onClick={handleReportTitle}>
                    <FcPrint size={15} className={GetCustomStyles().fa_style} />
                    {<GetLanguageString props="common_print" />}
                  </Button>
                </ButtonGroup>
              ) : null}
            </div>
            <PDFExport
              ref={pdfExportComponent}
              fileName="CustomMembersReport"
              scale={0.6}
              paperSize="A4"
              alignItems="center"
            >
              <div className="ibox-content ibox-center" id="section-to-print">
                <Row className="print">
                  <div className="text-center">
                    <h3>Title : {reportTitle}</h3>
                  </div>
                </Row>
                <h1 className="text-center ur">
                  {" "}
                  {reportSearchMemberModel.unitName} ( {unitType} )
                </h1>
                <br></br>
                {!subUnitLoading ? (
                  <>
                    {unitType !== "Tanzeem" &&
                    unitType !== "Munfarid" &&
                    unitType !== "Usra" &&
                    unitMembers.length !== 0 ? (
                      <div>
                        {unitMembers?.map((record, index) => {
                          totalCount.mubtadiCount = 0;
                          totalCount.multazimCount = 0;
                          totalCount.tawajahTalabCount = 0;
                          totalCount.mutazirCount = 0;
                          totalCount.totalMemberCount = 0;
                          return record.unitTypeName !== "Munfarid" &&
                            record.unitTypeName !== "UsraMuavin" ? (
                            <div key={index} className="panel panel-primary">
                              <div className="panel-heading">
                                <h2 className="ur text-center">
                                  {record.unitName}
                                </h2>
                              </div>
                              <div className="table-responsive">
                                {record?.unitMembers?.length !== 0 ? (
                                  <Table className="table-bordered m-b-none">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>
                                          <GetLanguageString props="member_member_detail_common_responsibility" />
                                        </th>
                                        <th>
                                          <GetLanguageString props="common_name" />
                                        </th>
                                        <th>
                                          <GetLanguageString props="member_reg_number" />
                                        </th>
                                        <th>
                                          <GetLanguageString props="settings_member_type" />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {record?.unitMembers.map(
                                        (record, index1) => {
                                          index1 === 0
                                            ? (className =
                                                "highlight-row-officials")
                                            : (className = "");
                                          return (
                                            <tr
                                              key={index1}
                                              className={className}
                                            >
                                              <td>{index1 + 1}</td>
                                              <td>
                                                {record.responsbilities?.map(
                                                  (item, index) => {
                                                    return (
                                                      <h4
                                                        className="ur"
                                                        key={index}
                                                      >
                                                        {item}
                                                      </h4>
                                                    );
                                                  }
                                                )}
                                              </td>
                                              <td className="ur">
                                                {record.name}
                                              </td>
                                              <td>{record.regNo}</td>
                                              <td className="ur">
                                                {record.memberTypeName}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                ) : (
                                  <NoResult />
                                )}
                              </div>
                              <div class="divider div-transparent div-dot"></div>
                              <div className="table-responsive">
                                {record?.unitMembersCount?.length !== 0 ? (
                                  <Table className="table-bordered m-b-none">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>
                                          <GetLanguageString props="quick_reports3_unit_name" />
                                        </th>
                                        <th>
                                          <GetLanguageString props="common_name" />
                                        </th>
                                        <th>
                                          <GetLanguageString props="member_reg_number" />
                                        </th>
                                        <th>
                                          <GetLanguageString props="settings_member_type" />
                                        </th>
                                        <th>
                                          <GetLanguageString props="member_member_detail_common_responsibility" />
                                        </th>
                                        <th className="text-center">
                                          {<GetLanguageString props="quick_reports3_mubtadi" />}
                                        </th>
                                        <th className="text-center">
                                          {
                                            <GetLanguageString props="quick_reports3_multazim" />
                                          }
                                        </th>
                                        <th className="text-center">
                                          {<GetLanguageString props="quick_reports3_mutazir" />}
                                        </th>
                                        <th className="text-center">
                                          {
                                            <GetLanguageString props="quick_reports3_tawajah_talab" />
                                          }
                                        </th>
                                        <th className="text-center">
                                          {
                                            <GetLanguageString props="report_member_total_members" />
                                          }
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {record?.unitMembersCount.map(
                                        (record, index1) => {
                                          totalCount.mubtadiCount += record.mubtadiCount;
                                          totalCount.multazimCount += record.multazimCount;
                                          totalCount.tawajahTalabCount +=
                                            record.tawajahTalabCount;
                                          totalCount.mutazirCount += record.mutazirCount;
                                          totalCount.totalMemberCount += record.totalMemberCount;
                                          return (
                                            <tr key={index1}>
                                              <td>{index1 + 1}</td>
                                              <td className="ur">
                                                <h4>{record.member.unitName}</h4>
                                              </td>
                                              <td className="ur">
                                                {record.member.name}
                                              </td>
                                              <td>{record.member.regNo}</td>
                                              <td className="ur">
                                                {record.member.memberTypeName}
                                              </td>
                                              <td>
                                                {record.member.responsbilities?.map(
                                                  (item, index) => {
                                                    return (
                                                      <h4
                                                        className="ur"
                                                        key={index}
                                                      >
                                                        {item}
                                                      </h4>
                                                    );
                                                  }
                                                )}
                                              </td>
                                              <td className="text-center">
                                                {record.mubtadiCount}
                                              </td>
                                              <td className="text-center">
                                                {record.multazimCount}
                                              </td>
                                              <td className="text-center">
                                                {record.mutazirCount}
                                              </td>
                                              <td className="text-center">
                                                {record.tawajahTalabCount}
                                              </td>
                                              <td className="text-center">
                                                {record.totalMemberCount}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="text-center" style={customStyles}>
                                          {totalCount.mubtadiCount}
                                        </td>
                                        <td className="text-center" style={customStyles}>
                                          {totalCount.multazimCount}
                                        </td>
                                        <td className="text-center" style={customStyles}>
                                          {totalCount.mutazirCount}
                                        </td>
                                        <td className="text-center" style={customStyles}>
                                          {totalCount.tawajahTalabCount}
                                        </td>
                                        <td className="text-center" style={customStyles}>
                                          {totalCount.totalMemberCount}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                ) : (
                                  <NoResult />
                                )}
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                    ) : unitMembers.length !== 0 ? (
                      <div className="panel panel-primary">
                        <div className="panel-heading">
                          <>
                            <h3 className="text-center">
                              <GetLanguageString props="unit_members_reports_sub_unit" />
                            </h3>
                            <h2 className="ur text-center">
                              {reportSearchMemberModel.unitName}
                            </h2>
                          </>
                        </div>

                        <div className="table-responsive">
                          <Table className="table-bordered m-b-none">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>
                                  <GetLanguageString props="member_reg_number" />
                                </th>
                                <th>
                                  <GetLanguageString props="common_name" />
                                </th>
                                <th>
                                  <GetLanguageString props="settings_member_type" />
                                </th>
                                <th>
                                  <GetLanguageString props="quick_reports3_unit_name" />
                                </th>
                                <th>
                                  <GetLanguageString props="member_member_detail_common_responsibility" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {unitMembers?.map((record, index) => {
                                return record?.unitMembers.map(
                                  (record, index1) => {
                                    return (
                                      <tr key={index1} className={className}>
                                        <td>{index1 + 1}</td>
                                        <td>{record.regNo}</td>
                                        <td className="ur">{record.name}</td>
                                        <td className="ur">
                                          {record.memberTypeName}
                                        </td>
                                        <td className="ur">
                                          <h4>{record.unitName}</h4>
                                        </td>
                                        <td>
                                          {record.responsbilities?.map(
                                            (item, index) => {
                                              return (
                                                <h4 className="ur" key={index}>
                                                  {item}
                                                </h4>
                                              );
                                            }
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  LoadingSpinner()
                )}
                {unitMembers.length === 0 ? (
                  <NoResult />
                ) : null}
              </div>
            </PDFExport>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UnitJaizaReport;
