import axios from "axios";
import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function ApiDocuments() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "document/";
  return {
    addDocument: (record) =>
      authAxios.post(url + "UploadFile/", record, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),

    editFile: (id) => authAxios.get(url + "edit/" + id),
    updateFile: (record) => authAxios.post(url + "edit", record),
    downloadDocument: (fileId) => authAxios.get(url + "DownloadFile/" + fileId),
    getDocuments: (model) => authAxios.post(url + "GetDocuments/", model),
    delete: (id) => authAxios.delete(url + "Delete/" + id),
    getJobProgress: (jobId, fileId) =>
      authAxios.get(`${url}GetJobProgress/${jobId}/${fileId}`),
    GetDocumentTags: () => authAxios.get(url + "GetDocumentTags/"),
  };
}

export function ApiFolders() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "folder/";
  return {
    addFolder: (record) => authAxios.post(url + "add", record),
    editFolder: (id) => authAxios.get(url + "edit/" + id),
    updateFolder: (record) => authAxios.post(url + "edit", record),
    delete: (id) => authAxios.delete(url + "Delete/" + id),
    getFolderHierarchy: (folderUnitId, folderId) =>
      authAxios.get(
        url + "GetFolderHierarchy/" + folderUnitId + "/" + folderId
      ),
    uploadFile: (formData) =>
      authAxios.post(url + "UploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    GetStaticFolderRights: () => authAxios.get(url + "GetStaticFolderRights/"),
  };
}
