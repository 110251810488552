import React, { useCallback, useEffect, useState } from "react";
import { GetLanguageString } from "../helper/Components";
import { ApiDocuments } from "./ApiDocuments";

const UploadStatus = ({
  fileStatus,
  jobId,
  uploadRefreshDocuments,
  fileId,
}) => {
  const [jobProgress, setJobProgress] = useState(1);
  const [fileUploaded, setFileUploaded] = useState(false);

  const fetchJobProgress = useCallback(() => {
    if (
      fileStatus !== "file_uploaded" &&
      jobId &&
      jobProgress > 0 &&
      jobProgress < 100 &&
      fileId
    ) {
      ApiDocuments()
        .getJobProgress(jobId, fileId)
        .then((res) => {
          setJobProgress(res.data.progress);
          setFileUploaded(res.data.fileUploaded);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [jobId, fileStatus, jobProgress, fileId]);
  useEffect(() => {
    let interval;

    const timeoutId = setTimeout(() => {
      fetchJobProgress();
      interval = setInterval(fetchJobProgress, 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeoutId);
    };
  }, [fetchJobProgress]);

  useEffect(() => {
    if (jobProgress === 100 && fileUploaded) {
      uploadRefreshDocuments();
      setFileUploaded(false);
    }
    // eslint-disable-next-line
  }, [jobProgress, fileUploaded]);

  const isInProgress =
    fileStatus !== "file_uploaded" && jobId && jobProgress > 1;

  return (
    <span>
      <GetLanguageString
        props={isInProgress ? "file_in_progress" : fileStatus}
      />{" "}
      {isInProgress ? `${jobProgress}%` : ""}
    </span>
  );
};

export default UploadStatus;
